<template>
  <div>

    <b-card title="Witaj w naszej aplikacji🚀">
      <b-card-text>Właśnie tutaj masz możliwość zarządzania swoim biznesem</b-card-text>
      <b-card-text>Zerknij także na naszą politykę prywatności<b-link>
        Klik
      </b-link> </b-card-text>
    </b-card>

    <b-card-body>
      <h5 class="apply-job-title mb-2">
        Nowe funkcje w programie (ver 0.32 z dnia 01.08.2022)
      </h5>

      <!-- payment  -->
      <div class="apply-job-package bg-light-primary rounded">
        <ul>
          <li>Poprawa generacji raportów</li>
          <li>Kalendarz</li>
          <li>Widok pracownika</li>
        </ul>
      </div>

    </b-card-body>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  mounted() {
    document.title = 'Witaj w naszej aplikacji🚀 - Pickmode'
  },
}
</script>

<style>

</style>
